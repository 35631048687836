import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Button, Text, Input, Avatar, Box, Image, Icon, Grid, MenuItem, Select as ChakraSelect } from '@chakra-ui/react';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import _sortBy from 'lodash.sortby';
import Select from 'react-select';
import { JoinLinesDocument, JoinLinesMutation, JoinLinesMutationVariables, LinesDocument, LineSpotStatus, LinesQuery, LinesQueryVariables, OnLineUpdatedDocument, UserByEmailDocument, UserByEmailQuery, LeaveLinesDocument, LeaveLinesMutation, LeaveLinesMutationVariables, UpdateLineSpotStatusDocument, UpdateLineSpotStatusMutation, UpdateLineSpotStatusMutationVariables, LineSpot, SendReminderMutation, SendReminderMutationVariables, SendReminderDocument, SitesQuery, SitesQueryVariables, SitesDocument } from '../../generated/graphql';
import Duck from '../../components/DuckSVG';
import BrandLogo from '../../components/BrandSVG';
import Header from '../../components/Header';
import { filterActionableLineSpot } from '../../utils/utils';
import { useLocation } from 'wouter';

type LinesProps = {

} & {
    params: { id: string }
}

type FormInputs = {
    location: string,
    lines: { value: string, label: string }[];
    intent: string,
  };

const Lines: React.FC<LinesProps> = (props) => {
    const { user } = useAuth0();
    const { data: userData } = useQuery<UserByEmailQuery>(UserByEmailDocument, { variables: { email: user?.email || '' } })
    const userId = userData?.userByEmail?.id;
    const [location, setLocation] = useLocation();
    
    const { data: allLinesData } = useQuery<LinesQuery, LinesQueryVariables>(LinesDocument, { variables: { siteId: props.params.id }});
    const { data: allSitesData } = useQuery<SitesQuery, SitesQueryVariables>(SitesDocument);
    const lines = React.useMemo(() => {
        return allLinesData?.allLines.filter((l) => l.isActive);
    }, [allLinesData]);
    const [joinLines] = useMutation<JoinLinesMutation, JoinLinesMutationVariables>(JoinLinesDocument)
    const [leaveLines] = useMutation<LeaveLinesMutation, LeaveLinesMutationVariables>(LeaveLinesDocument);
    const [updateLineSpotStatus] = useMutation<UpdateLineSpotStatusMutation, UpdateLineSpotStatusMutationVariables>(UpdateLineSpotStatusDocument);
    const [sendReminder] = useMutation<SendReminderMutation, SendReminderMutationVariables>(SendReminderDocument);
    // const { data: lineUpdatedData, loading: lineUpdatedLoading } = useSubscription(OnLineUpdatedDocument);
    const lineUpdatedData = useSubscription(OnLineUpdatedDocument);
    const userName = userData?.userByEmail ? `${userData.userByEmail.firstName} ${userData.userByEmail.lastName}` : user?.name;
    const [forceResetFormState, setForceResetFormState] = React.useState(0);

    const linesToOwnLineTickets = React.useMemo(() => {
        const data: {[key: string]: string} = {};
        lines?.forEach((l) => {
            l.LineSpots?.forEach((lS) => {
                if (lS?.duck.id === userId && l.id && lS?.lineTicket.id) {
                    if (!lS.lineTicket.completionTime) {
                        data[l.id] = lS?.lineTicket.id;
                    }
                }
            })
        });
        return data;
    }, [lines]);

    const mostRecentLineTicket = React.useMemo(() => {
        const lineSpots = lines?.flatMap((l) => l.LineSpots).filter((ls) => ls?.duck.id === userId).filter(filterActionableLineSpot);
        if (!lineSpots) {
            return null;
        }
        // if (!!lineSpots[0]?.lineTicket.completionTime) {
        //     return;
        // }
        return lineSpots[0]?.lineTicket;
    }, [lines]);

    const [mostRecentLineSpot, mostAdvancedPosition] = React.useMemo(() => {
        const lineSpots = mostRecentLineTicket?.lineSpots.filter(filterActionableLineSpot);
        if (!lineSpots) {
            return [];
        }
        // Always prefer the present line spot regardless of time waited
        const presentSpotIndex = lineSpots.findIndex((ls) => ls.duckReportStatus === LineSpotStatus.Present);

        if (presentSpotIndex !== -1) {
            return [lineSpots[presentSpotIndex], 0];
        }
        let mostAdvancedPosition = 10000;
        // FIXME: I don't think this makes sense, linespots don't have time
        let mostAdvancedPositionLineSpot = (_sortBy(lineSpots, 'startTime') as (typeof lineSpots))[0];

        const jLines = lines?.filter((l) => lineSpots.some((ls) => ls.lineId === l.id));
        lineSpots.forEach((ls) => {
            jLines?.forEach((l) => {
                const initialSortedLineSpots = _sortBy(l.LineSpots?.filter(filterActionableLineSpot).filter((ls) => {
                    return !(ls?.duck.id !== userId && ls?.duckReportStatus === LineSpotStatus.Hold);
                }), (l) => l?.lineTicket.startTime);
                const presentLineSpots = initialSortedLineSpots.filter((ls) => ls?.duckReportStatus === LineSpotStatus.Present);
                const notPresentLineSpots = initialSortedLineSpots.filter((ls) => ls?.duckReportStatus !== LineSpotStatus.Present);
                const sortedLineSpots = [...presentLineSpots, ...notPresentLineSpots];

                const i = sortedLineSpots.findIndex((joinedLineLineSpot) => joinedLineLineSpot?.id === ls.id);
                if (i === undefined) {
                    return [];
                }
                if (i !== -1 && i < mostAdvancedPosition) {
                    mostAdvancedPosition = i;
                    mostAdvancedPositionLineSpot = ls;
                }
            })
        })
        return [mostAdvancedPositionLineSpot, mostAdvancedPosition];
    }, [lines, userId, mostRecentLineTicket]);

    console.log({ mostRecentLineSpot, mostAdvancedPosition });
    const joinedLines = React.useMemo(() => lines?.filter((l) => {
        if (!mostRecentLineTicket) {
            return;
        }
        return l.LineSpots?.some((ls) => mostRecentLineTicket.lineSpots.some((mostRecentLineSpt) => ls?.id === mostRecentLineSpt.id));
    }), [lines, mostRecentLineTicket]);
    // We need to more accurately zip these together so if you're at the front
    // of one line and the back of another, you will always show as up next
    const joinedLineLineSpots = React.useMemo(() => {
        const mostRecentLineSpotLine = mostRecentLineSpot?.lineId; 
        const mostRecentLineSpots = _sortBy(joinedLines?.find((l) => l.id === mostRecentLineSpotLine)?.LineSpots, (s) => new Date(s?.lineTicket.startTime));
        const restLineSpots = _sortBy(joinedLines?.filter((l) => l.id !== mostRecentLineSpotLine).flatMap((l) => l.LineSpots), (s) => new Date(s?.lineTicket.startTime));
        const lineSpots = [...mostRecentLineSpots, ...restLineSpots].filter(filterActionableLineSpot);
        if (!lineSpots) {
            return null;
        }
        return lineSpots;
    }, [joinedLines]);

    // const joinedLinePosition = joinedLineLineSpots?.findIndex((ls) => ls?.id === mostRecentLineSpot?.id);
    const mostRecentLineSpotLine = lines?.find((l) => l.id === mostRecentLineSpot?.lineId);
    const joinedLinePosition = mostAdvancedPosition; // sortedMostRecentLineSpotLineSpots?.findIndex((ls: any) => ls.id === mostRecentLineSpot?.id);

    const { register, handleSubmit, control, watch, reset, getValues, formState: { errors, isValid } } = useForm<FormInputs>({ mode: 'onChange', reValidateMode: 'onChange' });

    const { lines: formLineIds } = watch();

    const onSubmit = () => {
        if (!userId) {
            return;
        }
        const vals = getValues();
        const existingTicketId = Object.values(linesToOwnLineTickets)[0];
        joinLines({
            variables: {
                data: {
                    duckId: String(userId),
                    existingTicketId,
                    location: vals.location,
                    intent: vals.intent,
                    lineIds: vals.lines.map((v) => v.value),
                }
            }
        }).then((res) => {
            console.log("successfuly joined a line", res);
        })
    }

    const onLeaveLine = () => {
        const siteId = joinedLines?.[0]?.Site?.id;
        if (!siteId || !mostRecentLineSpot) {
            return;
        }
        const lineSpotIds = joinedLineLineSpots?.filter((ls) => {
            return ls?.duck.id === userId;
        }).map((a) => a?.id).filter((a) => a !== undefined);
        if (!lineSpotIds?.length) {
            throw new Error('no line spots to vacate');
        }
        leaveLines({
            variables: {
                data: {
                    lineSpotIds: lineSpotIds as string[],
                    siteId,
                }
            }
        }).then((res) => {
            console.log("successfuly left the line", res);
        })
    }

    // Only reset form if the most recent line spot changes (i.e. you finished your appt) -- only reason this is here instead of below is because the deps below are too inclusive
    React.useEffect(() => {
        if (!mostRecentLineSpot) {
            reset({
                location: "",
                intent: "",
                lines: []
            });
        }
    }, [mostRecentLineSpot]);

    // This is for when a faculty completes your request
    React.useEffect(() => {
        const vals = getValues();
        if (vals.location || vals.intent) {
            return;
        }
        reset({
            location: mostRecentLineTicket?.location || "",
            intent: mostRecentLineTicket?.intent || "",
            lines: joinedLines?.map((l) => ({ value: l.id, label: l.label || `${l.head?.firstName} ${l.head?.lastName}` })) || undefined,
        }, { keepValues: false });
      }, [mostRecentLineSpot, joinedLines, mostRecentLineTicket]);

    console.log({ values: getValues(), isValid, userData, joinedLines, linesToOwnLineTickets, joinedLineLineSpots, mostRecentLineSpot })

    const renderBottomBar = React.useCallback(() => {
        if (mostRecentLineSpot && joinedLines) {
            const leadLine = lines?.find((l) => l.id === mostRecentLineSpot.lineId);
            let lineHeadName = joinedLines.map((joinedLine) => {
                return joinedLine.label || `${joinedLine.head?.firstName} ${joinedLine.head?.lastName}`
            }).join(', ') ;
            let lineText;
            let barColor;
            const areLinesSame = formLineIds?.every((val) => joinedLines.some((jL) => jL.id === val.value )) && joinedLines?.every((val) => formLineIds.some((jL) => val.id === jL.value ));
            let buttons = [
                <Button key="change_line" disabled={!(isValid && !areLinesSame)} onClick={onSubmit}>
                    Update Lines
                </Button>,
                <Button key="leave_line" onClick={onLeaveLine}>
                    Leave Line
                </Button>
            ]

            switch (joinedLinePosition) {
                case 0:
                    if (mostRecentLineSpot?.duckReportStatus === LineSpotStatus.Present) {
                        lineHeadName = leadLine?.label || `${leadLine?.head?.firstName} ${leadLine?.head?.lastName}`;
                        lineText = `You are with ${lineHeadName}`;
                        barColor = 'green';
                        buttons = [
                            <Button key="line_leader_present" onClick={() => {
                                if (!mostRecentLineSpot) {
                                    console.error('Tried update status, but had no mostRecentLineSpot');
                                    // TODO: toast an error
                                    return;
                                }
                                updateLineSpotStatus({
                                    variables: {
                                        data: {
                                            lineSpotId: mostRecentLineSpot.id,
                                            headStatus: LineSpotStatus.Completed,
                                            // TODO: this could be inaccurate, but assuming this for now
                                            duckStatus: LineSpotStatus.Completed,
                                        }
                                    }
                                })
                                setForceResetFormState(1);
                            }}>
                                Complete
                            </Button>,
                                                    // <Button key="send_reminder" onClick={() => {
                                                    //     sendReminder({
                                                    //         variables: {
                                                    //             lineSpotId: mostRecentLineSpot?.id
                                                    //         }
                                                    //     })
                                                    // }}>
                                                    //     Send Reminder
                                                    // </Button>
                        ]
                        break;
                    } else if (mostRecentLineSpot?.duckReportStatus === LineSpotStatus.Hold) {
                        lineText = 'You are on hold';
                        barColor = 'yellow';
                        buttons = [
                            <Button key="remove_hold" onClick={() => {
                                if (!mostRecentLineSpot) {
                                    console.error('Tried update status, but had no mostRecentLineSpot');
                                    // TODO: toast an error
                                    return;
                                }
                                updateLineSpotStatus({
                                    variables: {
                                        data: {
                                            lineSpotId: mostRecentLineSpot?.id,
                                            headStatus: LineSpotStatus.Active,
                                            // TODO: this could be inaccurate, but assuming this for now
                                            duckStatus: LineSpotStatus.Active,
                                        }
                                    }
                                })
                            }}>
                                Ready
                            </Button>
                        ]
                        break;
                    } else if (mostRecentLineSpot?.duckReportStatus === LineSpotStatus.Flagged) {
                        lineText = 'You have been flagged';
                        barColor = '#D0B6B6';
                        buttons = [
                            <Button key="line_leader_present" onClick={() => {
                                if (!mostRecentLineSpot) {
                                    console.error('Tried update status, but had no mostRecentLineSpot');
                                    // TODO: toast an error
                                    return;
                                }
                                updateLineSpotStatus({
                                    variables: {
                                        data: {
                                            lineSpotId: mostRecentLineSpot?.id,
                                            duckStatus: LineSpotStatus.Active,
                                        }
                                    }
                                })
                            }}>
                                Resolve
                            </Button>
                        ];
                        break;
                    } else {
                        lineText = "You're up!";
                        barColor = 'red';
                    }
                    buttons = [
                        <Button key="line_leader_present" onClick={() => {
                            if (!mostRecentLineSpot) {
                                console.error('Tried update status, but had no mostRecentLineSpot');
                                // TODO: toast an error
                                return;
                            }
                            updateLineSpotStatus({
                                variables: {
                                    data: {
                                        lineSpotId: mostRecentLineSpot?.id,
                                        headStatus: LineSpotStatus.Present,
                                        // TODO: this could be inaccurate, but assuming this for now
                                        duckStatus: LineSpotStatus.Present,
                                    }
                                }
                            })
                        }}>
                            Lead Present
                        </Button>,
                        <Button key="send_reminder" onClick={() => {
                            sendReminder({
                                variables: {
                                    lineSpotId: mostRecentLineSpot?.id
                                }
                            })
                        }}>
                            Send Reminder
                        </Button>
                    ].concat(buttons);
                    break;
                case 1:
                    if (mostRecentLineSpot?.duckReportStatus === LineSpotStatus.Hold) {
                        lineText = 'You are on hold';
                        barColor = 'yellow';
                        buttons = [
                            <Button key="remove_hold" onClick={() => {
                                if (!mostRecentLineSpot) {
                                    console.error('Tried update status, but had no mostRecentLineSpot');
                                    // TODO: toast an error
                                    return;
                                }
                                updateLineSpotStatus({
                                    variables: {
                                        data: {
                                            lineSpotId: mostRecentLineSpot?.id,
                                            headStatus: LineSpotStatus.Active,
                                            // TODO: this could be inaccurate, but assuming this for now
                                            duckStatus: LineSpotStatus.Active,
                                        }
                                    }
                                })
                            }}>
                                Ready
                            </Button>
                        ]
                        break;
                    }
                    lineText = `You're next up in ${lineHeadName}'s line.`
                    barColor = 'yellow';
                    break;
                case 2:
                    if (mostRecentLineSpot?.duckReportStatus === LineSpotStatus.Hold) {
                        lineText = 'You are on hold';
                        barColor = 'yellow';
                        buttons = [
                            <Button key="remove_hold" onClick={() => {
                                if (!mostRecentLineSpot) {
                                    console.error('Tried update status, but had no mostRecentLineSpot');
                                    // TODO: toast an error
                                    return;
                                }
                                updateLineSpotStatus({
                                    variables: {
                                        data: {
                                            lineSpotId: mostRecentLineSpot?.id,
                                            headStatus: LineSpotStatus.Active,
                                            // TODO: this could be inaccurate, but assuming this for now
                                            duckStatus: LineSpotStatus.Active,
                                        }
                                    }
                                })
                            }}>
                                Ready
                            </Button>
                        ]
                        break;
                    }
                    lineText = `You're in ${lineHeadName}'s line`;
                    break;
            }
            return (
                <Grid gridGap={2} backgroundColor={barColor} p={4} my={2} borderRadius="md">
                    <Text fontWeight={600}>{lineText}</Text>
                    {buttons}
                </Grid>
            )    
        } else {
            return (
                <Grid gridGap={2} p={4} my={2} borderRadius="md">
                    <Button disabled={!isValid} onClick={onSubmit}>
                        Join Line
                    </Button>
                </Grid>
            )
        }
    }, [joinedLines, isValid, joinedLinePosition, formLineIds])

    return (
        <Flex flexDirection="column" height="100%">
            <Header name={userName}>
                <MenuItem onClickCapture={(e) => {
                    e.stopPropagation();
                }} width="100%">
                    <ChakraSelect key={allSitesData?.sites.length} styles={{ width: "100%" }} defaultValue={props.params.id} onChange={(val) => {
                        setLocation(`/site/${val.currentTarget.value}`)
                    }}>
                        {allSitesData?.sites.map((s) => {
                            return (<option key={s.id} value={s.id}>{s.name}</option>)
                        })}
                    </ChakraSelect>
                </MenuItem>
            </Header>
            <Flex height="100%" flexDirection="column" px={[4, 4, 52]} justifyContent="space-between" mt={12}>
            <Flex>
                <Flex flexDirection="column" flex={3}>
                    <Input placeholder="Where are you?" {...register('location', { required: true })}/>
                    <Box mt={4} width="100%">
                        <Controller
                            name="lines"
                            control={control}
                            render={({ field }) => (
                                    <Select 
                                        {...field}
                                        placeholder="Select a line to join"
                                        isMulti={true}
                                        options={lines?.map((l) => {
                                            return {
                                                label: l.label || `${l.head?.firstName} ${l.head?.lastName} (${l.LineSpots?.filter(filterActionableLineSpot).length})`,
                                                value: l.id,
                                            }
                                        })}
                                    />
                            )}
                        />
                    </Box>
                    <Input mt={4} placeholder="Request reason" {...register('intent', { required: true })} />
                </Flex>
                {!!joinedLineLineSpots?.length && (
                    <Flex flexDirection="column" flex={1} alignItems="center">
                        {/* <Avatar />
                        <Avatar />
                        <Avatar borderWidth={2} borderColor="blue" borderStyle="solid" /> */}
                        {
                            joinedLineLineSpots?.map((ls, i) => {
                                const border = ls?.id === mostRecentLineSpot?.id ? { borderWidth: 2, borderColor: "#F5A640", borderStyle:"solid" } : {}
                                return <Avatar transform={`scale(${1 - (i * .1)})`} p={3} backgroundColor="#FFD45c" icon={<Duck />} key={ls?.id || i} {...border} />
                            }).flatMap((val, i) => {
                                return (
                                    <Flex flexDirection="column" key={i}>
                                        {val}
                                    </Flex>
                                )
                            })
                        }
                    </Flex>
                )}
            </Flex>
            {renderBottomBar()}
        </Flex>
        </Flex>
    )
}

export default Lines;