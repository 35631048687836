import React from 'react';
import logo from './logo.svg';
import './App.css';
import Profile from './components/Profile';
import Router from './Router';
import { Box } from '@chakra-ui/react';

function App() {
  return (
    <Box className="App" height="100vh" overflow="hidden" >
      <Router />
    </Box>
  );
}

export default App;
