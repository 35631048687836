import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    HttpLink,
    split,
    ApolloLink,
    concat
  } from "@apollo/client";
import { API_HOST, WS_HOST } from "./config/config";
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from "@apollo/client/utilities";
import { getAccessToken } from "./utils/utils";

const wsLink = new WebSocketLink({
  uri: WS_HOST,
  options: {
    reconnect: true
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${getAccessToken()}` || null,
    }
  }));

  return forward(operation);
})

const httpLink = new HttpLink({
  uri: `${API_HOST}/graphql`,
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  concat(authMiddleware, httpLink),
);

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
});

export default client;