import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH_AUDIENCE, AUTH_CLIENT_ID, AUTH_DOMAIN, AUTH_SCOPE } from './config/config';
import { RecoilRoot } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import client from './client';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import Fonts from './components/Fonts';

ReactDOM.render(
  <React.StrictMode>
      <Auth0Provider
        domain={AUTH_DOMAIN}
        clientId={AUTH_CLIENT_ID}
        redirectUri={`${window.location.origin}/callback`}
        audience={AUTH_AUDIENCE}
        scope={AUTH_SCOPE}
        cacheLocation="localstorage"
        useRefreshTokens={true}
    >
      <ApolloProvider client={client} >
        <RecoilRoot>
          <ChakraProvider theme={theme}>
            <Fonts />
            <App />
          </ChakraProvider>
        </RecoilRoot>
      </ApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

setInterval(() => {
  client.refetchQueries({
    include: "active",
  });
}, 2 * 60 * 1000)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
