import * as React from 'react';

import { useMutation, useSubscription, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import _sortBy from 'lodash.sortby';
import { DateTime, Duration } from 'luxon';

import { UserByEmailQuery, UserByEmailDocument, LinesQuery, LinesQueryVariables, LinesDocument, JoinLinesMutation, JoinLinesMutationVariables, JoinLinesDocument, LeaveLinesMutation, LeaveLinesMutationVariables, LeaveLinesDocument, UpdateLineSpotStatusMutation, UpdateLineSpotStatusMutationVariables, UpdateLineSpotStatusDocument, OnLineUpdatedDocument, LineQuery, LineQueryVariables, LineDocument, UserByEmailQueryVariables, SetLineActiveStatusMutation, SetLineActiveStatusMutationVariables, SetLineActiveStatusDocument, LineSpotStatus, OnSendReminderDocument, OnSendReminderSubscription, JoinSiteMutation, JoinSiteMutationVariables, JoinSiteDocument, SitesDocument, SitesQuery, SitesQueryVariables } from '../../generated/graphql';
import { filterActionableLineSpot } from '../../utils/utils';
import { Table, TableCaption, Thead, Tr, Th, Grid, Tbody, Td, Tfoot, Flex, Text, Button, useToast, Select as ChakraSelect, MenuItem, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import Header from '../../components/Header';
import { useLocation } from 'wouter';

type LineProps = {

} & {
    params: { id: string, lineId: string }
}

const Line: React.FC<LineProps> = (props) => {
    /**
     * TODO:
     *  Top section that shows the current student at the head of the line
     *  Buttons for: Completing, Flagging, Reminding
     *  List with line spots: Position, Name, Time Waited, "Call Type"
     *  Buttons on bottom for: Pausing or unpausing line, a question mark?, and plus?
     */

     const { user } = useAuth0();
     const { data: userData } = useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, { variables: { email: user?.email || '' } })
     const userId = userData?.userByEmail?.id;
     const userName = userData?.userByEmail ? `${userData.userByEmail.firstName} ${userData.userByEmail.lastName}` : user?.name;
     const toast = useToast()
     const [location, setLocation] = useLocation();

     const [forceUpdateCount, setForceUpdate] = React.useState(0);
     
     const { data: lineData } = useQuery<LineQuery, LineQueryVariables>(LineDocument, { variables: { lineId: props.params.lineId }});
     const { data: allSitesData } = useQuery<SitesQuery, SitesQueryVariables>(SitesDocument);
     const line = lineData?.line;
     const [joinLines] = useMutation<JoinLinesMutation, JoinLinesMutationVariables>(JoinLinesDocument)
     const [leaveLines] = useMutation<LeaveLinesMutation, LeaveLinesMutationVariables>(LeaveLinesDocument);
     const [updateActiveStatus] = useMutation<SetLineActiveStatusMutation, SetLineActiveStatusMutationVariables>(SetLineActiveStatusDocument);
     const [updateLineSpotStatus] = useMutation<UpdateLineSpotStatusMutation, UpdateLineSpotStatusMutationVariables>(UpdateLineSpotStatusDocument);
     const [joinSite] = useMutation<JoinSiteMutation, JoinSiteMutationVariables>(JoinSiteDocument);
     // const { data: lineUpdatedData, loading: lineUpdatedLoading } = useSubscription(OnLineUpdatedDocument);
     const lineUpdatedData = useSubscription(OnLineUpdatedDocument);
     const reminderLineSpot = useSubscription<OnSendReminderSubscription>(OnSendReminderDocument);
     const [reminderDuckId, setReminderDuckId] = React.useState<string>();

     console.log({ lineUpdatedData });

     const sortedLineSpots = React.useMemo(() => {
         const lineSpots = line?.LineSpots?.filter(filterActionableLineSpot).filter((ls) => ls?.duckReportStatus !== LineSpotStatus.Flagged && ls?.duckReportStatus !== LineSpotStatus.Hold) || [];
         const sortedLineSpotsByTime = (_sortBy(lineSpots, (l) => l?.lineTicket.startTime) as (typeof lineSpots));
         const presentLineSpots = sortedLineSpotsByTime.filter((ls) =>  ls?.duckReportStatus === LineSpotStatus.Present);
         const notPresentLineSpots = sortedLineSpotsByTime.filter((ls) =>  ls?.duckReportStatus !== LineSpotStatus.Present);
         return [...presentLineSpots, ...notPresentLineSpots];
     }, [line]);

     const sortedHoldLineSpots = React.useMemo(() => {
        const lineSpots = line?.LineSpots?.filter((ls) => ls?.duckReportStatus === LineSpotStatus.Hold) || [];
        return (_sortBy(lineSpots, (l) => l?.lineTicket.startTime) as (typeof lineSpots));
    }, [line]);

    const sortedCompletedLineSpots = React.useMemo(() => {
        const lineSpots = line?.LineSpots?.filter((ls) => ls?.duckReportStatus === LineSpotStatus.Completed) || [];
        return (_sortBy(lineSpots, (l) => l?.lineTicket.startTime) as (typeof lineSpots));
    }, [line]);

     React.useEffect(() => {
         const interval = setInterval(() => {
             setForceUpdate(forceUpdateCount + 1);
         }, 10000);
         return () => clearInterval(interval);
     }, []);

     React.useEffect(() => {
        const { firstName, lastName } = reminderLineSpot?.data?.lineSpotReminder?.duck || {};
         if (firstName) {
             toast({
                 title: `${firstName} ${lastName || ''} is waiting`,
                 duration: 9000,
                 isClosable: true,
                 status: 'warning'
             })
         }
     }, [reminderDuckId]);

     React.useEffect(() => {
         setReminderDuckId(reminderLineSpot.data?.lineSpotReminder?.duck.id)
     }, [reminderLineSpot]);

     const renderActiveDuckling = React.useCallback(() => {
         if (!activeLineSpot) {
            return (
                <Flex width="100%" justifyContent="center" alignItems="center">
                <Text fontSize="xx-large" fontWeight="bold">
                    Nobody in Line
                </Text>
            </Flex>
            )
         }
         if (!line?.isActive) {
             return (
                 <Flex width="100%" justifyContent="center" alignItems="center">
                     <Text fontSize="xx-large" fontWeight="bold">
                        LINE PAUSED
                     </Text>
                 </Flex>
             )
         }
         return (
             <Flex width="100%">
                 <Flex flex={1} alignItems="center" p={4}>
                     <Text fontSize="lg">
                     {`${activeLineSpot?.duck.firstName} ${activeLineSpot?.duck.lastName}`}
                     </Text>
                 </Flex>
                 <Flex flex={1} flexDirection="column" width="100%" justifyContent="space-evenly">
                     <Text fontSize="xx-large" fontWeight="bold">
                        {activeLineSpot?.lineTicket.location}
                     </Text>
                     <Text fontSize="lg">
                        {activeLineSpot?.lineTicket.intent}
                     </Text>
                 </Flex>
             </Flex>
         )
     }, [sortedLineSpots]);

     const renderTable = React.useCallback(() => {
         return (
             <Flex flexDirection="column" flex={3}>
                <Table variant="simple">
                    <Thead>
                    <Tr>
                        <Th>#</Th>
                        <Th>Name</Th>
                        <Th>Time Waited</Th>
                        <Th>Call Type</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                        {sortedLineSpots.filter((ls) => ls?.duckReportStatus !== LineSpotStatus.Present).map((sLS, i) => {
                            const diff = DateTime.now().diff(DateTime.fromISO(sLS?.lineTicket.startTime)).milliseconds;
                            const duration = Duration.fromMillis(diff).toFormat("m'm's's'");
                            return (
                                <Tr key={sLS?.id || i}>
                                    <Td>{i + 1}</Td>
                                    <Td>{`${sLS?.duck.firstName} ${sLS?.duck.lastName}`}</Td>
                                    <Td>{duration}</Td>
                                    <Td>{sLS?.lineTicket.intent}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
             </Flex>
         )
     }, [sortedLineSpots, forceUpdateCount]);

     const renderHoldTable = React.useCallback(() => {
        if (!sortedHoldLineSpots.length) {
            return null;
        }
        return (
            <Flex flexDirection="column" flex={3} backgroundColor="#F4E2CE" mb={2}>
                <Text fontWeight="bold">On Hold</Text>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                        <Th>#</Th>
                        <Th>Name</Th>
                        <Th>Time Waited</Th>
                        <Th>Call Type</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {sortedHoldLineSpots.map((sLS, i) => {
                            const diff = DateTime.now().diff(DateTime.fromISO(sLS?.lineTicket.startTime)).milliseconds;
                            const duration = Duration.fromMillis(diff).toFormat("m'm's's'");
                            console.log({ duration });
                            return (
                                <Tr key={sLS?.id || i}>
                                    <Td>{i + 1}</Td>
                                    <Td>{`${sLS?.duck.firstName} ${sLS?.duck.lastName}`}</Td>
                                    <Td>{duration}</Td>
                                    <Td>{sLS?.lineTicket.intent}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </Flex>
        )
    }, [sortedLineSpots, forceUpdateCount]);

    const renderCompletedTable = React.useCallback(() => {
        if (!sortedCompletedLineSpots.length) {
            return null;
        }
        return (
            <Flex flexDirection="column" flex={3} mb={2}>
                <Accordion allowToggle={true}>
                <AccordionItem backgroundColor="blackAlpha.50">
                    <AccordionButton>
                        <Text fontWeight="bold">Completed Requests</Text>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                        <Table variant="simple">
                                <Thead>
                                    <Tr>
                                    <Th>#</Th>
                                    <Th>Name</Th>
                                    <Th>Time Waited</Th>
                                    <Th>Call Type</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {sortedCompletedLineSpots.map((sLS, i) => {
                                        const diff = DateTime.fromISO(sLS?.lineTicket.completionTime).diff(DateTime.fromISO(sLS?.lineTicket.startTime)).milliseconds;
                                        const duration = Duration.fromMillis(diff).toFormat("m'm's's'");
                                        return (
                                            <Tr key={sLS?.id || i}>
                                                <Td>{i + 1}</Td>
                                                <Td>{`${sLS?.duck.firstName} ${sLS?.duck.lastName}`}</Td>
                                                <Td>{duration}</Td>
                                                <Td>{sLS?.lineTicket.intent}</Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                        </Table>
                    </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        )
    }, [sortedCompletedLineSpots, forceUpdateCount]);

    const renderActions = React.useCallback(() => {
        if (!activeLineSpot) {
            return;
        }
        return (
            <Grid flex={1} gridGap={2} gridAutoFlow="column" px="10%" py={4}>
                <Button onClick={() => {
                    updateLineSpotStatus({
                        variables: {
                            data: {
                                lineSpotId: activeLineSpot.id,
                                duckStatus: LineSpotStatus.Flagged
                            }
                        }
                    })
                }}>Flag</Button>
                {activeLineSpot.duckReportStatus === LineSpotStatus.Present ? (
                    <Button onClick={() => {
                        // TODO: for now only student can mark as completed
                        updateLineSpotStatus({
                            variables: {
                                data: {
                                    lineSpotId: activeLineSpot.id,
                                    headStatus: LineSpotStatus.Completed,
                                    duckStatus: LineSpotStatus.Completed,
                                }
                            }
                        })
                    }}>Mark as Completed</Button>
                ) : (<Button onClick={() => {
                    updateLineSpotStatus({
                        variables: {
                            data: {
                                lineSpotId: activeLineSpot.id,
                                headStatus: LineSpotStatus.Present,
                                duckStatus: LineSpotStatus.Present,
                            }
                        }
                    })
                }}>Mark as Present</Button>)}
                {activeLineSpot.duckReportStatus === LineSpotStatus.Present && (<Button onClick={() => {
                    updateLineSpotStatus({
                        variables: {
                            data: {
                                lineSpotId: activeLineSpot.id,
                                headStatus: LineSpotStatus.Hold,
                                duckStatus: LineSpotStatus.Hold,
                            }
                        }
                    })
                }}>Place on Hold</Button>)}
            </Grid>
        )
    }, [sortedLineSpots]);

    const renderLineActions = React.useCallback(() => {
        const cbFactory = (isActive: boolean) => () => {
            if (!line) {
                return;
            }
            updateActiveStatus({
                variables: {
                    lineId: line?.id,
                    isActive,
                }
            })
        }
        return (
            <Flex flex={1} justifyContent="center">
                {line?.isActive ? <Button onClick={cbFactory(false)}>Pause</Button> : <Button onClick={cbFactory(true)}>Activate</Button>}
            </Flex>
        )
    }, [line]);

    let backgroundColor;
    const activeLineSpot = sortedLineSpots.find((ls) => ls?.headReportStatus === LineSpotStatus.Present) || sortedLineSpots[0];

    if (!line?.isActive) {
        backgroundColor = 'grey';
    } else if (activeLineSpot?.duckReportStatus === LineSpotStatus.Present) {
        backgroundColor = 'green';
    } else if (activeLineSpot?.duckReportStatus === LineSpotStatus.Active || activeLineSpot?.duckReportStatus === LineSpotStatus.Passive) {
        backgroundColor = 'red';
    }

    return (
        <Flex flexDirection="column" height="100%">
            <Header name={userName}>
                <MenuItem onClickCapture={(e) => {
                        e.stopPropagation();
                    }} width="100%">
                        <ChakraSelect key={allSitesData?.sites.length} defaultValue={props.params.id} onChange={(val) => {
                            if (userId && val) {
                                const siteId = val.currentTarget.value;
                                joinSite({
                                    variables: {
                                        data: {
                                            userId,
                                            siteId
                                        }
                                    }
                                }).then((r) => {
                                    const newLine = r.data?.joinSite?.lines.find((l) => l.siteId === siteId);
                                    if (newLine) {
                                        setLocation(`/site/${siteId}/line/${props.params.lineId}`)
                                    }
                                })
                            }
                        }}>
                            {allSitesData?.sites.map((s) => {
                                return (<option key={s.id} value={s.id}>{s.name}</option>)
                            })}
                        </ChakraSelect>
                </MenuItem>
            </Header>
            <Flex width="100%" flex={2} backgroundColor={backgroundColor}>
                {renderActiveDuckling()}
            </Flex>
            {renderActions()}
            {renderTable()}
            {renderHoldTable()}
            {renderCompletedTable()}
            {renderLineActions()}
        </Flex>
    )
}

export default Line;
