import { Icon, IconProps } from '@chakra-ui/react';

const BrandLogo = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 1582.54541015625 2048.0" {...props}>
            <g id="document" transform="matrix(1,0,0,1,791.272705078125,1024.0)">
                <path d="M-66.794,420.344 L280.715,420.344 C280.715,420.344 280.715,-67.9743 280.715,-195.244 C280.715,-347.787 157.056,-471.446 4.51312,-471.446 C-148.03,-471.446 -270.787,-347.787 -270.787,-195.244 L-270.787,216.352 C-270.787,329.179 -179.622,420.344 -66.794,420.344 Z " fill="none" strokeWidth="69.12" stroke="#000000" strokeLinecap="square" strokeLinejoin="miter" />
                <path d="M-332.059,141.88 L-622.17,-25.5511 L-332.059,-192.982 L-332.059,141.88 Z " fill="#000000" fillOpacity="1.00" />
                <path d="M218.247,355.761 L218.247,188.33 C218.247,95.8022 143.344,20.899 50.8163,20.899 C-41.7112,20.899 -116.614,95.8022 -116.614,188.33 C-116.614,280.857 -41.7112,355.761 50.8163,355.761 L218.247,355.761 Z " fill="#000000" fillOpacity="1.00" />
                <path d="M340.357,184.568 L340.357,454.904 L418.468,454.904 C492.879,454.904 553.636,394.146 553.636,319.736 C553.636,245.325 492.879,184.568 418.468,184.568 L340.357,184.568 Z " fill="#000000" fillOpacity="1.00" />
            </g>
        </Icon>
    )
}

export default BrandLogo;