import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import { API_HOST } from "../config/config";
import { Box, Flex, Text } from "@chakra-ui/react";
import Header from "./Header";
import { Redirect, useLocation } from "wouter";
import { getAccessToken, setAccessToken } from "../utils/utils";

const Profile: React.FC<{ onDone: (done: boolean) => void }> = (props) => {
    const { user, isAuthenticated, getAccessTokenSilently, error } = useAuth0();
    const [isLoadingAuth, setIsLoadingAuth] = React.useState(false);
    const location = useLocation();
    const globalAccessToken = getAccessToken();

    React.useEffect(() => {
        const getUserMetadata = async () => {
          setIsLoadingAuth(true);
          setAccessToken('');
          const domain = API_HOST;
      
          try {
            const accessToken = await getAccessTokenSilently({
              audience: `${domain}`,
            })

            setAccessToken(accessToken);
          } catch (e) {
            console.log(e);
          } finally {
            setIsLoadingAuth(false);
          }
        };
        
        if (!isAuthenticated || !globalAccessToken) {
          getUserMetadata();
        }
      }, [user, isAuthenticated, globalAccessToken]);

    console.log({ user, isAuthenticated, isLoadingAuth });
    const accessToken = getAccessToken();

    React.useEffect(() => {
      if (isAuthenticated && accessToken) {
        props.onDone(true);
      }
    }, [isAuthenticated, isLoadingAuth, accessToken])

    if (!user || !isAuthenticated || isLoadingAuth) {
        return (
          <Flex flexDirection="column" position="relative" width="100vw" height="100vh" justifyContent="center" alignItems="center">
            <Box width="100%" position="fixed" top={0}>
              <Header />
            </Box>
            <Flex p={12} flexDirection="column" borderWidth="2px" borderStyle="solid" borderColor="blackAlpha.400" borderRadius="md" height="40vh" width="60vw" justifyContent="space-around" alignItems="center">
              <Text fontSize="x-large" fontWeight="bold">
                Welcome to Ducks in a Row!
              </Text>
              <Text fontSize="small" fontWeight="light">
                Log in and if you don't currently have a site you can join or create one
              </Text>
              <LoginButton />
            </Flex>
          </Flex>
        )
    }
    return <Redirect to="/" />;
};

export default Profile;