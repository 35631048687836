import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Epilogue', sans-serif;
        src: local('Epilogue'), url(./fonts/Epilogue/Epilogue-VariableFont_wght.ttf) format('ttf');
      }
      `}
  />
)

export default Fonts