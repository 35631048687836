import { Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import { Redirect, Route, Switch } from 'wouter';
import Profile from './components/Profile';
import { User, UserByEmailDocument, UserByEmailQuery } from './generated/graphql';
import Line from './pages/Line/Line';
import Lines from './pages/Lines/Lines';
import Start from './pages/Start/Start';
import { getAccessToken } from './utils/utils';

const Root: React.FC<{}> = () => {
    // This will decide what site they belong to and route them to the lines, line page
    // If they don't belong to a site it'll send them to the start page
    const { user } = useAuth0();
    const { loading, error, data } = useQuery<UserByEmailQuery>(UserByEmailDocument, { variables: { email: user?.email || '' } })
    if (loading) {
        return <Text>Loading...</Text>;
    }
    const siteId = data?.userByEmail?.account.siteId;
    const userLines = data?.userByEmail?.lines;
    if (siteId) {
        if (userLines?.length) {
            return (
                <Redirect href={`site/${siteId}/line/${userLines[0].id}`} />
            )
        } else {
            return (
                <Redirect href={`site/${siteId}`} />
            )
        }
    }
    return (
        <Redirect href="/start" />
    )
}

const AuthedRouter: React.FC<{}> = () => {
    return (
        <Switch>
            <Route path='/start' component={Start} />
            <Route path='/site/:id' component={Lines} />
            <Route path='/site/:id/line/:lineId' component={Line} />
            <Route component={Root} />
        </Switch>
    )
}

const AnonRouter: React.FC<{onDone: (isDone: boolean) => void}> = (props) => {
    return <Profile onDone={props.onDone} />
}

type RouterProps = {
}

const Router: React.FC<RouterProps> = () => {
    const { isAuthenticated } = useAuth0();
    const [hasAuthed, setHasAuthed] = React.useState(false);
    return isAuthenticated && hasAuthed ? <AuthedRouter /> : <AnonRouter onDone={setHasAuthed} />
}

export default Router;