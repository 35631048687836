import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import { AccountTypes, SitesDocument, SitesQuery, AddSiteDocument, AddSiteMutation, Site, CreateUserAccountMutation, CreateUserAccountDocument, LineSpotStatus } from '../../generated/graphql';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useAuth0 } from '@auth0/auth0-react';
import Select from 'react-select';
import CreateableSelect from 'react-select/creatable'
import { Button, Flex, Input, Text } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';

import { sites as sitesAtom } from './atoms';
import { useLocation } from 'wouter';
import Header from '../../components/Header';

type StartProps = {

}

type StartFormInputs = {
    email: string;
    firstName: string;
    lastName: string;
    accountType: { value: number, label: string };
    siteValue: { value: string, label: string };
};

// const AccountTypeDisplayStrings = [[AccountTypes.Duck, 'Duck'], [AccountTypes.Duckling, 'Duckling']] as any[][];
const AccountTypeDisplayStrings = [[AccountTypes.Duck, 'Faculty'], [AccountTypes.Duckling, 'Student']] as any[][];

const StartForm: React.FC<{}> = () => {
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm<StartFormInputs>();
    const { user } = useAuth0();
    const [location, setLocation] = useLocation();
    const [createUserAccount] = useMutation<CreateUserAccountMutation>(CreateUserAccountDocument)
    const onSubmit: SubmitHandler<StartFormInputs> = data => {
        // FIXME: Reenable user account creation later
        // createUserAccount({
        //     variables: {
        //         name: data.email,
        //         data: {
        //             email: data.email,
        //             firstName: data.firstName,
        //             lastName: data.lastName,
        //             accountType: data.accountType.value,
        //             siteId: String(data.siteValue.value),
        //         }
        //     }
        // }).then((res) => {
        //     const user = res.data?.createUserAccount;
        //     if (user?.account.type === AccountTypes.Duckling) {
        //         setLocation(`/site/${data.siteValue.value}`)
        //     } else if (user?.lines[0]) {
        //         setLocation(`/site/${data.siteValue.value}/line/${user.lines[0].id}`)
        //     }
        // })
    };
    const { loading, error, data } = useQuery<SitesQuery>(SitesDocument)
    const [addSite, { data: optimisticData }] = useMutation<AddSiteMutation>(AddSiteDocument)
    const [sites, setSites] = useRecoilState(sitesAtom)

    React.useEffect(() => {
        if (data) {
            setSites(data?.sites);
        }
    }, [data]);

    React.useEffect(() => {
        setSites((prev) => {
            if (optimisticData?.addSite) {
                return prev?.concat([optimisticData.addSite]) || null;
            }
            return prev;
        });
    }, [optimisticData]);
  
    console.log(watch("firstName")) // watch input value by passing the name of it

    const accountTypeOptions = AccountTypeDisplayStrings.flatMap((s) => {
        return { value: s[0], label: s[1]}
    });

    const siteOptions = sites?.map((s) => {
        return { value: s.id, label: s.name };
    });
  
    return (
        <Flex position="relative" flexDirection="column" width="100%" height="100vh" alignItems="center">
            <Header name={user?.name} />
            <Flex mt={12} p={12} borderWidth="2px" borderStyle="solid" borderColor="blackAlpha.400" borderRadius="md" flexDirection="column">
                <Text mb={4} fontSize="x-large" fontWeight="bold">Join an existing site, or create a new one</Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <input value={user?.email} type="hidden" {...register("email")} />
                    
                    {/* include validation with required or other standard HTML validation rules */}
                    <label>First Name</label>
                    <Input {...register("firstName", { required: true })} />
                    <label>Last Name</label>
                    <Input {...register("lastName", { required: true })} />

                    <label>Site</label>
                    <Controller
                        name="siteValue"
                        control={control}
                        rules={{ required:  true }}
                        render={({ field }) => (<CreateableSelect 
                            {...field} 
                            options={siteOptions}
                            onCreateOption={(name) => {
                                addSite({ variables: { name } });
                            }}
                        />)}
                    />
                    <label>Account Type</label>
                    <Controller
                        name="accountType"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (<Select 
                            {...field} 
                            options={accountTypeOptions}
                        />)}
                    />
                    {/* errors will return when field validation fails  */}
                    {(errors.firstName || errors.lastName) && <Text color="red">This field is required</Text>}
                    
                    <Button type="submit" variant="outline" colorScheme="yellow" mt={2}>Join</Button>
                </form>
            </Flex>
        </Flex>
    );
}

const Start: React.FC<StartProps> = () => {
    const [createUserAccount, { data }] = useMutation(CreateUserAccountDocument);
    return (
        <Flex>
            <StartForm />
        </Flex>
    )
}

export default Start;