import { LineSpot, LineSpotStatus } from "../generated/graphql";


let access_token = '';

export const filterActionableLineSpot = (lineSpot?: any) => {
    const ls = lineSpot as LineSpot;
    return (ls?.duckReportStatus === LineSpotStatus.Active || ls?.duckReportStatus === LineSpotStatus.Passive || ls?.duckReportStatus === LineSpotStatus.Present || ls.duckReportStatus === LineSpotStatus.Hold);
}

export const setAccessToken = (token: string) => {
    access_token = token;
    localStorage.setItem('ducks_access_token', token);
}

export const getAccessToken = () => {
    return access_token || localStorage.getItem('ducks_access_token');
}