import * as React from 'react';
import { Flex, Menu, MenuButton, MenuItem, MenuList, Portal, Select, Text } from '@chakra-ui/react';
import { user } from '../rootAtoms';
import BrandLogo from './BrandSVG';
import { useAuth0 } from '@auth0/auth0-react';

const Header: React.FC<{ name?: string }> = (props) => {
    const auth = useAuth0();
    return (
        <Flex justifyContent="space-between" width="100%" height="4rem" borderBottomWidth={1} borderColor="blackAlpha.100" borderStyle="solid" alignItems="center">
            <BrandLogo w="4rem" h="4rem" />
            <Menu>
                <MenuButton pr={4}>{`${props.name || ''}`}</MenuButton>
                <MenuList>
                    <MenuItem onClick={() => {
                        auth.logout({ returnTo: window.location.origin });
                    }}>Logout</MenuItem>
                    {props.children}
                </MenuList>
            </Menu>
        </Flex>
    )
}

export default Header;