import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents byte value as a Buffer */
  Bytes: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: any;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['String'];
  type: AccountTypes;
  userId: Scalars['String'];
  siteId: Scalars['String'];
  User: User;
};

/** account types */
export enum AccountTypes {
  Duck = 'DUCK',
  Superduck = 'SUPERDUCK',
  Duckling = 'DUCKLING'
}


export type ChangeLineInput = {
  previousLineId: Scalars['String'];
  newLineId: Scalars['String'];
  duckId: Scalars['String'];
};


export type JoinLineInput = {
  lineId: Scalars['String'];
  duckId: Scalars['String'];
  location: Scalars['String'];
  intent: Scalars['String'];
  existingTicketId?: Maybe<Scalars['String']>;
};

export type JoinLinesInput = {
  lineIds: Array<Scalars['String']>;
  duckId: Scalars['String'];
  location: Scalars['String'];
  intent: Scalars['String'];
  existingTicketId?: Maybe<Scalars['String']>;
};


export type LeaveLinesInput = {
  lineSpotIds: Array<Scalars['String']>;
  siteId: Scalars['String'];
};

export type Line = {
  __typename?: 'Line';
  id: Scalars['String'];
  headId?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  isActive: Scalars['Boolean'];
  head?: Maybe<User>;
  Site?: Maybe<Site>;
  LineSpots?: Maybe<Array<Maybe<LineSpot>>>;
};

export type LineSpot = {
  __typename?: 'LineSpot';
  id: Scalars['String'];
  lineId: Scalars['String'];
  duckId: Scalars['String'];
  lineTicketId: Scalars['String'];
  duckReportStatus: LineSpotStatus;
  headReportStatus: LineSpotStatus;
  duck: User;
  lineTicket: LineTicket;
  line: Line;
};

/** line spot statuses */
export enum LineSpotStatus {
  Active = 'ACTIVE',
  Checkedin = 'CHECKEDIN',
  Flagged = 'FLAGGED',
  Completed = 'COMPLETED',
  CompletedElsewhere = 'COMPLETED_ELSEWHERE',
  Hold = 'HOLD',
  Present = 'PRESENT',
  Paused = 'PAUSED',
  Passive = 'PASSIVE'
}

export type LineTicket = {
  __typename?: 'LineTicket';
  id: Scalars['String'];
  completionTime?: Maybe<Scalars['DateTime']>;
  startTime: Scalars['DateTime'];
  location?: Maybe<Scalars['String']>;
  intent: Scalars['String'];
  lineSpots: Array<LineSpot>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addSite?: Maybe<Site>;
  sendReminder?: Maybe<LineSpot>;
  joinSite?: Maybe<User>;
  setLineActiveStatus?: Maybe<Line>;
  leaveLines?: Maybe<Scalars['Boolean']>;
  joinLine?: Maybe<LineSpot>;
  joinLines?: Maybe<LineSpot>;
  changeLine?: Maybe<LineSpot>;
  updateLineSpotStatus?: Maybe<LineSpot>;
  createUserAccount?: Maybe<User>;
};


export type MutationAddSiteArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationSendReminderArgs = {
  lineSpotId?: Maybe<Scalars['String']>;
};


export type MutationJoinSiteArgs = {
  name?: Maybe<Scalars['String']>;
  data: UserJoinSiteInput;
};


export type MutationSetLineActiveStatusArgs = {
  lineId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type MutationLeaveLinesArgs = {
  data: LeaveLinesInput;
};


export type MutationJoinLineArgs = {
  data: JoinLineInput;
};


export type MutationJoinLinesArgs = {
  data: JoinLinesInput;
};


export type MutationChangeLineArgs = {
  data: ChangeLineInput;
};


export type MutationUpdateLineSpotStatusArgs = {
  data: UpdateLineSpotStatusInput;
};


export type MutationCreateUserAccountArgs = {
  name?: Maybe<Scalars['String']>;
  data: UserAccountCreateInput;
};

export type PostCreateInput = {
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allUsers: Array<User>;
  allLines: Array<Line>;
  allLineSpots: Array<LineSpot>;
  line: Line;
  sites: Array<Site>;
  user: User;
  userByEmail?: Maybe<User>;
};


export type QueryAllLinesArgs = {
  siteId?: Maybe<Scalars['String']>;
};


export type QueryAllLineSpotsArgs = {
  siteId?: Maybe<Scalars['String']>;
};


export type QueryLineArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryUserByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};

export type Site = {
  __typename?: 'Site';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  lines: Array<Line>;
};

export type Subscription = {
  __typename?: 'Subscription';
  lineUpdated?: Maybe<Line>;
  lineSpotsUpdated?: Maybe<Array<Maybe<LineSpot>>>;
  lineSpotReminder?: Maybe<LineSpot>;
};

export type UpdateLineSpotStatusInput = {
  lineSpotId: Scalars['String'];
  headStatus?: Maybe<LineSpotStatus>;
  duckStatus?: Maybe<LineSpotStatus>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  lines: Array<Line>;
  lineSpots: Array<LineSpot>;
  account: Account;
};

export type UserAccountCreateInput = {
  accountType: AccountTypes;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  siteId: Scalars['String'];
};

export type UserJoinSiteInput = {
  siteId: Scalars['String'];
  userId: Scalars['String'];
};

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserByEmailQuery = (
  { __typename?: 'Query' }
  & { userByEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
    & { lines: Array<(
      { __typename?: 'Line' }
      & Pick<Line, 'id'>
    )>, account: (
      { __typename?: 'Account' }
      & Pick<Account, 'siteId' | 'type'>
    ) }
  )> }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id'>
);

export type UpdateLineSpotStatusMutationVariables = Exact<{
  data: UpdateLineSpotStatusInput;
}>;


export type UpdateLineSpotStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateLineSpotStatus?: Maybe<(
    { __typename?: 'LineSpot' }
    & Pick<LineSpot, 'id'>
  )> }
);

export type SetLineActiveStatusMutationVariables = Exact<{
  lineId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
}>;


export type SetLineActiveStatusMutation = (
  { __typename?: 'Mutation' }
  & { setLineActiveStatus?: Maybe<(
    { __typename?: 'Line' }
    & Pick<Line, 'id' | 'isActive'>
  )> }
);

export type SendReminderMutationVariables = Exact<{
  lineSpotId?: Maybe<Scalars['String']>;
}>;


export type SendReminderMutation = (
  { __typename?: 'Mutation' }
  & { sendReminder?: Maybe<(
    { __typename?: 'LineSpot' }
    & Pick<LineSpot, 'id'>
  )> }
);

export type JoinSiteMutationVariables = Exact<{
  data: UserJoinSiteInput;
}>;


export type JoinSiteMutation = (
  { __typename?: 'Mutation' }
  & { joinSite?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { lines: Array<(
      { __typename?: 'Line' }
      & Pick<Line, 'id' | 'siteId'>
    )> }
  )> }
);

export type LineQueryVariables = Exact<{
  lineId?: Maybe<Scalars['String']>;
}>;


export type LineQuery = (
  { __typename?: 'Query' }
  & { line: (
    { __typename?: 'Line' }
    & Pick<Line, 'id' | 'label' | 'isActive'>
    & { Site?: Maybe<(
      { __typename?: 'Site' }
      & Pick<Site, 'id' | 'name'>
    )>, head?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )>, LineSpots?: Maybe<Array<Maybe<(
      { __typename?: 'LineSpot' }
      & Pick<LineSpot, 'id' | 'duckReportStatus' | 'headReportStatus'>
      & { lineTicket: (
        { __typename?: 'LineTicket' }
        & Pick<LineTicket, 'id' | 'location' | 'intent' | 'startTime' | 'completionTime'>
        & { lineSpots: Array<(
          { __typename?: 'LineSpot' }
          & Pick<LineSpot, 'id' | 'duckReportStatus' | 'headReportStatus'>
          & { duck: (
            { __typename?: 'User' }
            & Pick<User, 'id'>
          ) }
        )> }
      ), duck: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ) }
    )>>> }
  ) }
);

export type JoinLinesMutationVariables = Exact<{
  data: JoinLinesInput;
}>;


export type JoinLinesMutation = (
  { __typename?: 'Mutation' }
  & { joinLines?: Maybe<(
    { __typename?: 'LineSpot' }
    & Pick<LineSpot, 'id'>
  )> }
);

export type LeaveLinesMutationVariables = Exact<{
  data: LeaveLinesInput;
}>;


export type LeaveLinesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leaveLines'>
);

export type LinesQueryVariables = Exact<{
  siteId?: Maybe<Scalars['String']>;
}>;


export type LinesQuery = (
  { __typename?: 'Query' }
  & { allLines: Array<(
    { __typename?: 'Line' }
    & Pick<Line, 'id' | 'label' | 'isActive'>
    & { Site?: Maybe<(
      { __typename?: 'Site' }
      & Pick<Site, 'id' | 'name'>
    )>, head?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )>, LineSpots?: Maybe<Array<Maybe<(
      { __typename?: 'LineSpot' }
      & Pick<LineSpot, 'id' | 'duckReportStatus' | 'headReportStatus' | 'lineId'>
      & { lineTicket: (
        { __typename?: 'LineTicket' }
        & Pick<LineTicket, 'id' | 'location' | 'intent' | 'startTime' | 'completionTime'>
        & { lineSpots: Array<(
          { __typename?: 'LineSpot' }
          & Pick<LineSpot, 'id' | 'duckReportStatus' | 'headReportStatus' | 'lineId'>
          & { duck: (
            { __typename?: 'User' }
            & Pick<User, 'id'>
          ) }
        )> }
      ), duck: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )>>> }
  )> }
);

export type OnLineUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnLineUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { lineUpdated?: Maybe<(
    { __typename?: 'Line' }
    & Pick<Line, 'id' | 'label' | 'isActive'>
    & { Site?: Maybe<(
      { __typename?: 'Site' }
      & Pick<Site, 'id' | 'name'>
    )>, head?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )>, LineSpots?: Maybe<Array<Maybe<(
      { __typename?: 'LineSpot' }
      & Pick<LineSpot, 'id' | 'duckReportStatus' | 'headReportStatus' | 'lineId'>
      & { lineTicket: (
        { __typename?: 'LineTicket' }
        & Pick<LineTicket, 'id' | 'location' | 'intent' | 'startTime' | 'completionTime'>
        & { lineSpots: Array<(
          { __typename?: 'LineSpot' }
          & Pick<LineSpot, 'id' | 'duckReportStatus' | 'headReportStatus'>
          & { duck: (
            { __typename?: 'User' }
            & Pick<User, 'id'>
          ) }
        )> }
      ), duck: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )>>> }
  )> }
);

export type OnSendReminderSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnSendReminderSubscription = (
  { __typename?: 'Subscription' }
  & { lineSpotReminder?: Maybe<(
    { __typename?: 'LineSpot' }
    & { duck: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type CreateUserAccountMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  data: UserAccountCreateInput;
}>;


export type CreateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { createUserAccount?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { lines: Array<(
      { __typename?: 'Line' }
      & Pick<Line, 'id'>
    )>, account: (
      { __typename?: 'Account' }
      & Pick<Account, 'type'>
    ) }
  )> }
);

export type AddSiteMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type AddSiteMutation = (
  { __typename?: 'Mutation' }
  & { addSite?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  )> }
);

export type SitesQueryVariables = Exact<{ [key: string]: never; }>;


export type SitesQuery = (
  { __typename?: 'Query' }
  & { sites: Array<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
  )> }
);

export const UserFragmentDoc = gql`
    fragment user on User {
  id
}
    `;
export const UserByEmailDocument = gql`
    query userByEmail($email: String!) {
  userByEmail(email: $email) {
    id
    firstName
    lastName
    lines {
      id
    }
    account {
      siteId
      type
    }
  }
}
    `;

/**
 * __useUserByEmailQuery__
 *
 * To run a query within a React component, call `useUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
      }
export function useUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByEmailQuery, UserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByEmailQuery, UserByEmailQueryVariables>(UserByEmailDocument, options);
        }
export type UserByEmailQueryHookResult = ReturnType<typeof useUserByEmailQuery>;
export type UserByEmailLazyQueryHookResult = ReturnType<typeof useUserByEmailLazyQuery>;
export type UserByEmailQueryResult = Apollo.QueryResult<UserByEmailQuery, UserByEmailQueryVariables>;
export const UpdateLineSpotStatusDocument = gql`
    mutation updateLineSpotStatus($data: UpdateLineSpotStatusInput!) {
  updateLineSpotStatus(data: $data) {
    id
  }
}
    `;
export type UpdateLineSpotStatusMutationFn = Apollo.MutationFunction<UpdateLineSpotStatusMutation, UpdateLineSpotStatusMutationVariables>;

/**
 * __useUpdateLineSpotStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLineSpotStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLineSpotStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLineSpotStatusMutation, { data, loading, error }] = useUpdateLineSpotStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLineSpotStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLineSpotStatusMutation, UpdateLineSpotStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLineSpotStatusMutation, UpdateLineSpotStatusMutationVariables>(UpdateLineSpotStatusDocument, options);
      }
export type UpdateLineSpotStatusMutationHookResult = ReturnType<typeof useUpdateLineSpotStatusMutation>;
export type UpdateLineSpotStatusMutationResult = Apollo.MutationResult<UpdateLineSpotStatusMutation>;
export type UpdateLineSpotStatusMutationOptions = Apollo.BaseMutationOptions<UpdateLineSpotStatusMutation, UpdateLineSpotStatusMutationVariables>;
export const SetLineActiveStatusDocument = gql`
    mutation setLineActiveStatus($lineId: String, $isActive: Boolean) {
  setLineActiveStatus(lineId: $lineId, isActive: $isActive) {
    id
    isActive
  }
}
    `;
export type SetLineActiveStatusMutationFn = Apollo.MutationFunction<SetLineActiveStatusMutation, SetLineActiveStatusMutationVariables>;

/**
 * __useSetLineActiveStatusMutation__
 *
 * To run a mutation, you first call `useSetLineActiveStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLineActiveStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLineActiveStatusMutation, { data, loading, error }] = useSetLineActiveStatusMutation({
 *   variables: {
 *      lineId: // value for 'lineId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useSetLineActiveStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetLineActiveStatusMutation, SetLineActiveStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLineActiveStatusMutation, SetLineActiveStatusMutationVariables>(SetLineActiveStatusDocument, options);
      }
export type SetLineActiveStatusMutationHookResult = ReturnType<typeof useSetLineActiveStatusMutation>;
export type SetLineActiveStatusMutationResult = Apollo.MutationResult<SetLineActiveStatusMutation>;
export type SetLineActiveStatusMutationOptions = Apollo.BaseMutationOptions<SetLineActiveStatusMutation, SetLineActiveStatusMutationVariables>;
export const SendReminderDocument = gql`
    mutation sendReminder($lineSpotId: String) {
  sendReminder(lineSpotId: $lineSpotId) {
    id
  }
}
    `;
export type SendReminderMutationFn = Apollo.MutationFunction<SendReminderMutation, SendReminderMutationVariables>;

/**
 * __useSendReminderMutation__
 *
 * To run a mutation, you first call `useSendReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReminderMutation, { data, loading, error }] = useSendReminderMutation({
 *   variables: {
 *      lineSpotId: // value for 'lineSpotId'
 *   },
 * });
 */
export function useSendReminderMutation(baseOptions?: Apollo.MutationHookOptions<SendReminderMutation, SendReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReminderMutation, SendReminderMutationVariables>(SendReminderDocument, options);
      }
export type SendReminderMutationHookResult = ReturnType<typeof useSendReminderMutation>;
export type SendReminderMutationResult = Apollo.MutationResult<SendReminderMutation>;
export type SendReminderMutationOptions = Apollo.BaseMutationOptions<SendReminderMutation, SendReminderMutationVariables>;
export const JoinSiteDocument = gql`
    mutation joinSite($data: UserJoinSiteInput!) {
  joinSite(data: $data) {
    id
    lines {
      id
      siteId
    }
  }
}
    `;
export type JoinSiteMutationFn = Apollo.MutationFunction<JoinSiteMutation, JoinSiteMutationVariables>;

/**
 * __useJoinSiteMutation__
 *
 * To run a mutation, you first call `useJoinSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinSiteMutation, { data, loading, error }] = useJoinSiteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJoinSiteMutation(baseOptions?: Apollo.MutationHookOptions<JoinSiteMutation, JoinSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinSiteMutation, JoinSiteMutationVariables>(JoinSiteDocument, options);
      }
export type JoinSiteMutationHookResult = ReturnType<typeof useJoinSiteMutation>;
export type JoinSiteMutationResult = Apollo.MutationResult<JoinSiteMutation>;
export type JoinSiteMutationOptions = Apollo.BaseMutationOptions<JoinSiteMutation, JoinSiteMutationVariables>;
export const LineDocument = gql`
    query Line($lineId: String) {
  line(id: $lineId) {
    id
    label
    isActive
    Site {
      id
      name
    }
    head {
      firstName
      lastName
    }
    LineSpots {
      id
      duckReportStatus
      headReportStatus
      lineTicket {
        id
        location
        intent
        startTime
        completionTime
        lineSpots {
          id
          duckReportStatus
          headReportStatus
          duck {
            id
          }
        }
      }
      duck {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useLineQuery__
 *
 * To run a query within a React component, call `useLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLineQuery({
 *   variables: {
 *      lineId: // value for 'lineId'
 *   },
 * });
 */
export function useLineQuery(baseOptions?: Apollo.QueryHookOptions<LineQuery, LineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LineQuery, LineQueryVariables>(LineDocument, options);
      }
export function useLineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LineQuery, LineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LineQuery, LineQueryVariables>(LineDocument, options);
        }
export type LineQueryHookResult = ReturnType<typeof useLineQuery>;
export type LineLazyQueryHookResult = ReturnType<typeof useLineLazyQuery>;
export type LineQueryResult = Apollo.QueryResult<LineQuery, LineQueryVariables>;
export const JoinLinesDocument = gql`
    mutation joinLines($data: JoinLinesInput!) {
  joinLines(data: $data) {
    id
  }
}
    `;
export type JoinLinesMutationFn = Apollo.MutationFunction<JoinLinesMutation, JoinLinesMutationVariables>;

/**
 * __useJoinLinesMutation__
 *
 * To run a mutation, you first call `useJoinLinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLinesMutation, { data, loading, error }] = useJoinLinesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJoinLinesMutation(baseOptions?: Apollo.MutationHookOptions<JoinLinesMutation, JoinLinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinLinesMutation, JoinLinesMutationVariables>(JoinLinesDocument, options);
      }
export type JoinLinesMutationHookResult = ReturnType<typeof useJoinLinesMutation>;
export type JoinLinesMutationResult = Apollo.MutationResult<JoinLinesMutation>;
export type JoinLinesMutationOptions = Apollo.BaseMutationOptions<JoinLinesMutation, JoinLinesMutationVariables>;
export const LeaveLinesDocument = gql`
    mutation leaveLines($data: LeaveLinesInput!) {
  leaveLines(data: $data)
}
    `;
export type LeaveLinesMutationFn = Apollo.MutationFunction<LeaveLinesMutation, LeaveLinesMutationVariables>;

/**
 * __useLeaveLinesMutation__
 *
 * To run a mutation, you first call `useLeaveLinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveLinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveLinesMutation, { data, loading, error }] = useLeaveLinesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLeaveLinesMutation(baseOptions?: Apollo.MutationHookOptions<LeaveLinesMutation, LeaveLinesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveLinesMutation, LeaveLinesMutationVariables>(LeaveLinesDocument, options);
      }
export type LeaveLinesMutationHookResult = ReturnType<typeof useLeaveLinesMutation>;
export type LeaveLinesMutationResult = Apollo.MutationResult<LeaveLinesMutation>;
export type LeaveLinesMutationOptions = Apollo.BaseMutationOptions<LeaveLinesMutation, LeaveLinesMutationVariables>;
export const LinesDocument = gql`
    query Lines($siteId: String) {
  allLines(siteId: $siteId) {
    id
    label
    isActive
    Site {
      id
      name
    }
    head {
      firstName
      lastName
    }
    LineSpots {
      id
      duckReportStatus
      headReportStatus
      lineId
      lineTicket {
        id
        location
        intent
        startTime
        completionTime
        lineSpots {
          id
          duckReportStatus
          headReportStatus
          lineId
          duck {
            id
          }
        }
      }
      duck {
        id
      }
    }
  }
}
    `;

/**
 * __useLinesQuery__
 *
 * To run a query within a React component, call `useLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinesQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useLinesQuery(baseOptions?: Apollo.QueryHookOptions<LinesQuery, LinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinesQuery, LinesQueryVariables>(LinesDocument, options);
      }
export function useLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinesQuery, LinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinesQuery, LinesQueryVariables>(LinesDocument, options);
        }
export type LinesQueryHookResult = ReturnType<typeof useLinesQuery>;
export type LinesLazyQueryHookResult = ReturnType<typeof useLinesLazyQuery>;
export type LinesQueryResult = Apollo.QueryResult<LinesQuery, LinesQueryVariables>;
export const OnLineUpdatedDocument = gql`
    subscription onLineUpdated {
  lineUpdated {
    id
    label
    isActive
    Site {
      id
      name
    }
    head {
      firstName
      lastName
    }
    LineSpots {
      id
      duckReportStatus
      headReportStatus
      lineId
      lineTicket {
        id
        location
        intent
        startTime
        completionTime
        lineSpots {
          id
          duckReportStatus
          headReportStatus
          duck {
            id
          }
        }
      }
      duck {
        id
      }
    }
  }
}
    `;

/**
 * __useOnLineUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnLineUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnLineUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnLineUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnLineUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnLineUpdatedSubscription, OnLineUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnLineUpdatedSubscription, OnLineUpdatedSubscriptionVariables>(OnLineUpdatedDocument, options);
      }
export type OnLineUpdatedSubscriptionHookResult = ReturnType<typeof useOnLineUpdatedSubscription>;
export type OnLineUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnLineUpdatedSubscription>;
export const OnSendReminderDocument = gql`
    subscription onSendReminder {
  lineSpotReminder {
    duck {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useOnSendReminderSubscription__
 *
 * To run a query within a React component, call `useOnSendReminderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSendReminderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSendReminderSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSendReminderSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSendReminderSubscription, OnSendReminderSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnSendReminderSubscription, OnSendReminderSubscriptionVariables>(OnSendReminderDocument, options);
      }
export type OnSendReminderSubscriptionHookResult = ReturnType<typeof useOnSendReminderSubscription>;
export type OnSendReminderSubscriptionResult = Apollo.SubscriptionResult<OnSendReminderSubscription>;
export const CreateUserAccountDocument = gql`
    mutation createUserAccount($name: String, $data: UserAccountCreateInput!) {
  createUserAccount(name: $name, data: $data) {
    id
    lines {
      id
    }
    account {
      type
    }
  }
}
    `;
export type CreateUserAccountMutationFn = Apollo.MutationFunction<CreateUserAccountMutation, CreateUserAccountMutationVariables>;

/**
 * __useCreateUserAccountMutation__
 *
 * To run a mutation, you first call `useCreateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAccountMutation, { data, loading, error }] = useCreateUserAccountMutation({
 *   variables: {
 *      name: // value for 'name'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserAccountMutation, CreateUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserAccountMutation, CreateUserAccountMutationVariables>(CreateUserAccountDocument, options);
      }
export type CreateUserAccountMutationHookResult = ReturnType<typeof useCreateUserAccountMutation>;
export type CreateUserAccountMutationResult = Apollo.MutationResult<CreateUserAccountMutation>;
export type CreateUserAccountMutationOptions = Apollo.BaseMutationOptions<CreateUserAccountMutation, CreateUserAccountMutationVariables>;
export const AddSiteDocument = gql`
    mutation addSite($name: String) {
  addSite(name: $name) {
    id
    name
  }
}
    `;
export type AddSiteMutationFn = Apollo.MutationFunction<AddSiteMutation, AddSiteMutationVariables>;

/**
 * __useAddSiteMutation__
 *
 * To run a mutation, you first call `useAddSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSiteMutation, { data, loading, error }] = useAddSiteMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddSiteMutation(baseOptions?: Apollo.MutationHookOptions<AddSiteMutation, AddSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSiteMutation, AddSiteMutationVariables>(AddSiteDocument, options);
      }
export type AddSiteMutationHookResult = ReturnType<typeof useAddSiteMutation>;
export type AddSiteMutationResult = Apollo.MutationResult<AddSiteMutation>;
export type AddSiteMutationOptions = Apollo.BaseMutationOptions<AddSiteMutation, AddSiteMutationVariables>;
export const SitesDocument = gql`
    query sites {
  sites {
    id
    name
  }
}
    `;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSitesQuery(baseOptions?: Apollo.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
      }
export function useSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
        }
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = Apollo.QueryResult<SitesQuery, SitesQueryVariables>;